import { Injectable } from '@angular/core';
import { createClient, Entry } from 'contentful';
import { from } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContentfulTeamsService {

  constructor() { }

  private client = createClient({
    space: environment.teamspaceId,
    accessToken: environment.teamaccessToken
  });

  getAllEntries(){
    const promise = this.client.getEntries()
    return from(promise);
  }
}
