<link rel="stylesheet" href="https://unicons.iconscout.com/release/v4.0.0/css/line.css">
<!-- Footer -->
<footer  class="page-footer font-small blue-grey lighten-5">

    

    <!-- Footer Links -->
    <div class="container text-center text-md-left mt-5">

        <!-- Grid row -->
        <div class="row mt-3 dark-grey-text">

            <!-- Grid column -->
            <div class="col-md-3 col-lg-4 col-xl-3 mb-4">

                <!-- Content -->
                <h6 class="text-uppercase font-weight-bold">Sputnik Tech</h6>
                <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <p style="text-align:center;">{{'FooterContentSputnikTech' | translate}}
                </p>

            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                <!-- Links -->
                <h6 class="text-uppercase font-weight-bold">{{'Pages' | translate}}</h6>
                <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="/">{{'Home' | translate}}</a>
                </p>
                
                
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="/blogs">{{'Blogs' | translate}}</a>
                </p>
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="/projects">{{'Projects' | translate}}</a>
                </p>
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="/contact_us">{{'ContactUs' | translate}}</a>
                </p>
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="/">{{'teamButton' | translate}}</a>
                </p>
                
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <!--Links --> 
            <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

                
                <h6 class="text-uppercase font-weight-bold">{{'SocialMedia' | translate}}</h6>
                <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="https://www.facebook.com/sputniktechspb/"><i class="fab fa-2x fa-facebook-square"></i></a>
                </p>
                
                
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="https://www.instagram.com/sputnikmupa/"><i class="fab fa-2x fa-instagram-square"></i></a>
                </p>
                <p>
                    <a style="text-decoration:none" class="dark-grey-text" href="https://www.linkedin.com/company/sputnik-tech/"><i class="fab fa-2x fa-linkedin"></i></a>
                </p>
                
                
                
            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-4">

                <!-- Links -->
                <h6 class="text-uppercase font-weight-bold">{{'ContactUs' | translate}}</h6>
                <hr class="teal accent-3 mb-4 mt-0 d-inline-block mx-auto" style="width: 60px;">
                <p>
                    <a  href="https://wa.me/message/4QJ2NGZ7FJQZF1">
                    <i style="font-size:28px; color: #6C55F9;" class="uil uil-whatsapp"></i> Ru <i class="fas fa-mouse ms-2"></i>
                </a>
                </p>
                <p>
                    <a  href="https://wa.me/message/4B662PIKJJV4K1">
                    <i style="font-size:28px; color: #6C55F9;" class="uil uil-whatsapp"></i> Eng <i class="fas fa-mouse ms-2"></i>
                </a>
                </p>
                
                <p>
                  <a href="tel:+27682233859">
                    <i style="font-size:25px; color: #6C55F9;" class="uil uil-phone"></i> +27 68 223 3859
                </a>  
                </p>
                <p>
                    <i style="font-size:25px; color: #6C55F9;" class="uil uil-building"></i> {{'Address' | translate}}
                </p>
                <p>
                    <i style="font-size:25px; color: #6C55F9; text-align:justify; " class="uil uil-envelope"></i> info@sputniktech.co
                </p>
                
                
                
                


            </div>
            <!-- Grid column -->

        </div>
        <!-- Grid row -->

    </div>
    <!-- Footer Links -->
    <!-- Copyright -->
    <div class="footer-copyright text-center  py-3"><i class="uil uil-brackets-curly"></i> {{'DesignedBy' | translate}}
        <a style="text-decoration:none"  href="https://sputniktech.co/"> Sputnik Tech</a>
    </div>
    <!-- Copyright -->

    <!-- Copyright -->
    <div class="footer-copyright text-center  py-3">© {{ currentYear }} {{'Copyright' | translate}}:
        <a style="text-decoration:none" class="dark-grey-text"> Sputnik Tech</a>
    </div>
    <!-- Copyright -->

</footer>
<!-- Footer -->