<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.0/css/all.min.css" rel="stylesheet" />


<header class="mt-5">
  <div class="page-section mt-5">
    <div  class="container ">
      <div class="row">
        <div class="col-lg-6 py-3 wow fadeInLeft">
          <h2 data-aos="flip-left" data-aos-duration="1000" class="mb-4"  class="title-section"> <span class="marked">{{'headerSputnikTech' | translate}}</span> {{'headerOne' | translate}}</h2>
          <div class="divider"></div>
          <p  class="text-lg mb-5"><i style="color: #6C55F9;" class="fas fa-arrow-alt-circle-right "></i> {{'pointOne' | translate}}</p>
          <p  class="text-lg mb-5"><i style="color: #6C55F9;" class="fas fa-arrow-alt-circle-right "></i> {{'pointTwo' | translate}} <i style="color: #6C55F9;" class="fas fa-hand-point-right "></i> <a style="color: #6C55F9;" title="Click me" href="https://tradeybay.com"> Tradey Bay</a></p>
          <a  [routerLink]="['/projects']" class="btn btn-primary btn-split my-3 ml-2">{{'Projects' | translate}} <div class="fab"><span class="fas fa-arrow-alt-circle-right" ></span></div></a>
          <a  [routerLink]="['/blogs']" class="btn btn-primary btn-split ml-2">{{'Blogs' | translate}} <div class="fab"><span class="fas fa-arrow-alt-circle-right" ></span></div></a>
        </div>
        <div class="col-lg-6 py-3 wow zoomIn">
          <div class="img-place text-center">
            <ngb-carousel *ngIf="images" [showNavigationArrows]="showNavigationArrows"
                [showNavigationIndicators]="showNavigationIndicators">
                <ng-template ngbSlide *ngFor="let image of images; index as i; index as j">
                  <div class="picsum-img-wrapper">
                    <img  [src]="image" alt="Random slide" class="pic" />
                  </div>
                </ng-template>
              </ngb-carousel> 
          </div>
        </div>
      </div>
    </div>
    <!-- .container -->
  </div> <!-- .page-section -->
  <!-- <div class="relative" style="height: 36px;overflow: hidden;margin-top: -36px; z-index:2">
    <div class="w-full absolute bottom-0 start-0 end-0"
      style="transform: scale(2);transform-origin: top center;color: #6C55F9;">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div> -->
  <div class="custom-shape-divider-bottom-1667156080">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
</div>
<div class="custom-shape-divider-top-1667156508">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
      <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
      <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
  </svg>
</div>
</header>

<main>
  <section class="services section-bg " id="services">
    <div  class="container my-5">
      <header class="section-header">
        <div class="subhead"  >{{'headerOurServices' | translate}}</div>
        <h2 data-aos="flip-left" data-aos-duration="1000" class="title-section" >{{'headerServices' | translate}} <span class="marked">Sputnik Tech</span></h2>
        <div class="divider"></div>
      </header>
      <div class="row">
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="icon" style="background: #6C55F9;">
              
              <i class="fab fa-chrome service-icon" style="color: #c59c35;"></i>
            </div>
  
            <h4 class="title">{{'servicesOfferedOne' | translate}}</h4>
  
            <p class="description">{{'servicesOfferedOneP' | translate}}</p>
          </div>
        </div>

        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="icon" style="background: #6C55F9;">
              
              <i class="fas fa-desktop service-icon" style="color: #c59c35;"></i>
            </div>
  
            <h4 class="title">{{'servicesOfferedThree' | translate}}</h4>
  
            <p class="description">{{'servicesOfferedThreeP' | translate}}</p>
          </div>
        </div>
  
        <div class="col-md-6 col-lg-4">
          <div class="box">
            <div class="icon" style="background: #6C55F9;">
              
              <i class="fas fa-mobile-alt service-icon" style="color: #c59c35;"></i>
            </div>
  
            <h4 class="title">{{'servicesOfferedTwo' | translate}}</h4>
  
            <p class="description">{{'servicesOfferedTwoP' | translate}}</p>
          </div>
        </div>
  
        
  
  
      </div>
    </div>
   <!--  <div class="relative" style="height: 36px;overflow: hidden;margin-top: -36px; z-index:2">
      <div class="w-full absolute bottom-0 start-0 end-0"
        style="transform: scale(2);transform-origin: top center;color: #6C55F9;">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div> -->
    <div class="custom-shape-divider-bottom-1667156080">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
      </svg>
  </div>
  <div class="custom-shape-divider-top-1667156508">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
  </div>
  </section>






  
  <div class="page-section">
    <div  class="container mb-5">
      <div class="row">
        <div class="col-lg-6 py-3 wow zoomIn">
          <div class="img-place bounce text-center">
            <img src="https://res.cloudinary.com/dyzeuqi75/image/upload/v1665770672/bg_image_2_td7hn6.png" alt="">
          </div>
        </div>
        <div class="col-lg-6 py-3 wow fadeInRight">
          <div class="subhead">{{'headerOurTeam' | translate}}</div>
          <h2 data-aos="flip-left" data-aos-duration="1000" class="title-section">{{'teamHeaderOne' | translate}} <span class="marked">{{'teamHeaderD' | translate}}</span> {{'teamHeaderTwo' | translate}}</h2>
          <div class="divider"></div>
          <p><i style="color: #6C55F9;" class="fas fa-arrow-alt-circle-right "></i> {{'teamP' | translate}} </p>
          <a  [routerLink]="['/team']" class="btn btn-primary btn-split ml-2">{{'teamButton' | translate}} <div class="fab"><span class="fas fa-arrow-alt-circle-right" ></span></div></a>
          
        </div>
      </div>
    </div>
    <!-- <div class="relative" style="height: 36px;overflow: hidden;margin-top: -36px; z-index:2">
      <div class="w-full absolute bottom-0 start-0 end-0"
        style="transform: scale(2);transform-origin: top center;color: #6C55F9;">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div> -->
    <div class="custom-shape-divider-bottom-1667156080">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
      </svg>
  </div>
  <div class="custom-shape-divider-top-1667156508">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
  </div>
     <!-- .container -->
  </div> <!-- .page-section -->




  
  

  <div class="page-section">
    <div  class="container my-5">
      <div class="row">
        <div class="col-lg-6 py-3 ">
          <div class="subhead">{{'headerMarketing' | translate}}</div>
          <h2 data-aos="flip-left" data-aos-duration="1000" class="title-section">{{'marketingHeaderOne' | translate}} <span class="marked">{{'marketingHeaderR' | translate}}</span> {{'marketingHeaderTwo' | translate}}</h2>
          <div class="divider"></div>
          <p class="mb-5"><i style="color: #6C55F9;" class="fas fa-arrow-alt-circle-right "></i> {{'marketingP' | translate}}</p>
          
        </div>
        <div class="col-lg-6 py-3 wow zoomIn">
          <div class="img-place bounce text-center">
            <img src="https://res.cloudinary.com/dyzeuqi75/image/upload/v1665770291/1665770131450_m7vi4q.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="relative" style="height: 36px;overflow: hidden;margin-top: -36px; z-index:2">
      <div class="w-full absolute bottom-0 start-0 end-0"
        style="transform: scale(2);transform-origin: top center;color: #6C55F9;">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>  -->
    <div class="custom-shape-divider-bottom-1667156080">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
      </svg>
  </div>
  <div class="custom-shape-divider-top-1667156508">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
  </div>
    <!-- .container -->
  </div> <!-- .page-section -->

  <div class="page-section ">
    <div  class="container my-5 ">
      <div class="text-center wow fadeInUp">
        <div class="subhead">{{'chooseUsMarketing' | translate}}</div>
        <h2 data-aos="flip-left" data-aos-duration="1000" class="title-section">{{'chooseUsHeaderOne' | translate}} <span class="marked">{{'chooseUsHeaderR' | translate}}</span> {{'chooseUsHeaderTwo' | translate}}</h2>
        <div class="divider mx-auto"></div>
      </div>

      <div class="row mt-5 text-center">
        <div class="col-lg-4 py-3 bounce wow fadeInUp">
          <div class="display-3"><span class="mai-shapes"></span></div>
          <h5>{{'chooseUsContentHeaderOne' | translate}}</h5>
          <p>{{'chooseUsContentOne' | translate}}</p>
        </div>
        <div class="col-lg-4 py-3 bounce wow fadeInUp">
          <div class="display-3"><span class="mai-shapes"></span></div>
          <h5>{{'chooseUsContentHeaderTwo' | translate}}</h5>
          <p>{{'chooseUsContentTwo' | translate}}</p>
        </div>
        <div class="col-lg-4 py-3 bounce wow fadeInUp">
          <div class="display-3"><span class="mai-shapes"></span></div>
          <h5>{{'chooseUsContentHeaderThree' | translate}}</h5>
          <p>{{'chooseUsContentThree' | translate}}</p>
        </div>
      </div>
    </div> <!-- .container -->
  </div> <!-- .page-section -->
  <!-- <div class="relative" style="height: 36px;overflow: hidden;margin-top: -36px; z-index:2">
    <div class="w-full absolute bottom-0 start-0 end-0"
      style="transform: scale(2);transform-origin: top center;color: #6C55F9;">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div> -->
  <div class="custom-shape-divider-bottom-1667156080">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
</div>
<div class="custom-shape-divider-top-1667156508">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
      <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
      <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
  </svg>
</div>
  <div  class="page-section">
    <div  class="container my-5">
      <div class="row">
        <div class="col-lg-6 py-3 wow zoomIn">
          <div class="img-place bounce text-center">
            <img src="https://res.cloudinary.com/dyzeuqi75/image/upload/v1665770745/bg_image_3_n2kgke.png" alt="">
          </div>
        </div>
        <div class="col-lg-6 py-3 wow fadeInRight">
          <div class="subhead">{{'headerPricing' | translate}}</div>
          <h2 data-aos="flip-left" data-aos-duration="1000" class="title-section">{{'pricingHeaderOne' | translate}} <span class="marked">{{'pricingHeaderR' | translate}}</span> {{'pricingHeaderTwo' | translate}}</h2>
          <div class="divider"></div>
          <p><i style="color: #6C55F9;" class="fas fa-arrow-alt-circle-right "></i> {{'pricingPOne' | translate}}</p>
          <p><i style="color: #6C55F9;" class="fas fa-arrow-alt-circle-right "></i> {{'pricingPTwo' | translate}}</p>
          
          
        </div>
      </div>
    </div>
    <!-- <div class="relative" style="height: 36px;overflow: hidden;margin-top: -36px; z-index:2">
      <div class="w-full absolute bottom-0 start-0 end-0"
        style="transform: scale(2);transform-origin: top center;color: #6C55F9;">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>  -->
    <div class="custom-shape-divider-bottom-1667156080">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
      </svg>
  </div>
  <div class="custom-shape-divider-top-1667156508">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
  </div>
    <!-- .container -->
  </div> <!-- .page-section -->

  <div class="page-section ">
    <div  class="container mb-5">
      <div class="row">
        <div class="col-lg-6 py-3 wow fadeInLeft">
          <div class="subhead">{{'headerProjects' | translate}}</div>
          <h2  data-aos="flip-left" data-aos-duration="1000" class="title-section">{{'projectsHeaderOne' | translate}} <span class="marked">{{'projectsHeaderR' | translate}}</span> {{'projectsHeaderTwo' | translate}}</h2>
          <div class="divider"></div>
          <p><i style="color: #6C55F9;" class="fas fa-arrow-alt-circle-right "></i> {{'projectsP' | translate}} <i style="color: #6C55F9;" class="fas fa-hand-point-right "></i> <a style="color: #6C55F9;" title="Click me" href="https://tradeybay.com"> Tradey Bay</a></p>
          <p class="mb-5"><i style="color: #6C55F9;" class="fas fa-arrow-alt-circle-right "></i> {{'projectsPOne' | translate}}</p>
          <a  [routerLink]="['/projects']" class="btn btn-primary btn-split ml-2">{{'Projects' | translate}} <div class="fab"><span class="fas fa-arrow-alt-circle-right" ></span></div></a>
        </div>
        <div class="col-lg-6 py-3 wow zoomIn">
          <div class="img-place bounce text-center">
            <!---->
            <img src="https://res.cloudinary.com/dyzeuqi75/image/upload/v1665771451/bg_image_1_hmddx0.png" class="mb-5" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="relative" style="height: 36px;overflow: hidden;margin-top: -36px; z-index:2">
      <div class="w-full absolute bottom-0 start-0 end-0"
        style="transform: scale(2);transform-origin: top center;color: #6C55F9;">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>  -->
    <div class="custom-shape-divider-bottom-1667156080">
      <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
          <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
          <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
      </svg>
  </div>
  <div class="custom-shape-divider-top-1667156508">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
  </div>
    <!-- .container -->
  </div> <!-- .page-section -->

  

  <!-- -------- START FOOTER ------- -->
<section  data-scroll-index="0">
  <div class="container mb-5">
    <div class="row">
      <div class="col-md-12">
        <div class="welcome-content text-center">
          <h2 data-aos="flip-left" data-aos-duration="1000" class="title-section">{{'FAQS' | translate}}</h2>
          <div class="divider mx-auto"></div>

          <p style="text-align:center;">
            <script src="https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js"></script>
            <lottie-player src="https://assets8.lottiefiles.com/packages/lf20_mwhmbyym.json" background="transparent"
              speed="1" style="width: 150px; height: 150px;" loop autoplay></lottie-player>
          </p>
        </div>
      </div>
      <div class="col-md-12">
        <div class="welcome-content color text-center">
          <div class="faq-wrapper">
            <details>
              <summary class="disable-select">{{'FAQSQuestionOne' | translate}}</summary>
              <div>{{'FAQSAnswerOneOne' | translate}}
                <br> <br>
                {{'FAQSAnswerOneTwo' | translate}}
                <br> <br>
                {{'FAQSAnswerOneThree' | translate}}
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionTwo' | translate}}</summary>
              <div>{{'FAQSAnswerTwo' | translate}}              
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionThree' | translate}}</summary>
              <div>{{'FAQSAnswerThree' | translate}}
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionFour' | translate}}</summary>
              <div>{{'FAQSAnswerFour' | translate}}
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionFive' | translate}}</summary>
              <div>{{'FAQSAnswerFive' | translate}}                
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionSix' | translate}}</summary>
              <div>{{'FAQSAnswerSix' | translate}}                
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionSeven' | translate}}</summary>
              <div>{{'FAQSAnswerSeven' | translate}} </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionEight' | translate}}</summary>
              <div>{{'FAQSAnswerEight' | translate}}                 
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionNine' | translate}}</summary>
              <div>{{'FAQSAnswerNine' | translate}} 
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionTen' | translate}}</summary>
              <div>{{'FAQSAnswerTen' | translate}}
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionElleven' | translate}}</summary>
              <div>{{'FAQSAnswerElleven' | translate}}                
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionTwelve' | translate}}</summary>
              <div>{{'FAQSAnswerTwelve' | translate}}                
              </div>
            </details>
            <details>
              <summary class="disable-select">{{'FAQSQuestionThirteen' | translate}}</summary>
              <div>{{'FAQSAnswerThirteen' | translate}}</div>
            </details>
          </div>
        </div>
      </div>
    </div>
    
  </div>
  <!-- <div class="relative" style="height: 36px;overflow: hidden;margin-top: -36px; z-index:2">
    <div class="w-full absolute bottom-0 start-0 end-0"
      style="transform: scale(2);transform-origin: top center;color: #6C55F9;">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div> -->
  <div class="custom-shape-divider-bottom-1667156080">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
</div>
<div class="custom-shape-divider-top-1667156508">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
      <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
      <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
  </svg>
</div>
</section>

</main>


