
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css" />
<link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.0/css/all.min.css" rel="stylesheet" />


<!-- Begin Article
================================================== -->
<div *ngIf="blogPost$ | async as blogPost" class="container">
	<div class="row">

		<!-- Begin Post -->
		<div class="col-md-12 col-md-offset-2 col-xs-12">
			<div class="mainheading">

				<!-- Begin Top Meta -->
				<div class="row post-top-meta">
					<div class="col-md-12">
						<h6 data-aos="flip-left" data-aos-duration="1000" href="author.html"><i style="color: #6C55F9;" class="fas fa-3x fa-user-alt"></i>  {{blogPost.fields.author}}</h6>
						<div class="divider "></div>
						<span class="author-description">{{blogPost.fields.summary}}</span>
						<span class="post-date"><i style="color: #6C55F9;" class="fas fa-2x fa-clock"></i> {{blogPost.fields.updatedDate | date}}</span><span class="dot"></span>
						
					</div>
				</div>
				<!-- End Top Menta -->

				<h1 class="posttitle ">{{blogPost.fields.title}}</h1>
				<div class="divider "></div>
			</div>

			<!-- Begin Featured Image -->
			<img class="featured-image img-fluid" [src]="blogPost.fields.featuredImage.fields.file.url" alt="">
			<!-- End Featured Image -->

			<!-- Begin Post Content -->
			<div class="article-post">
				<p [innerHtml]="blogPost.fields.content | toHtml"></p>
			</div>
			<!-- End Post Content -->

			<!-- Begin Tags -->
			<div class="my-5" >
				<a  [routerLink]="['/blogs']" class="btn btn-primary btn-split ms-1">{{'BacktoBlogsButton' | translate}}<div class="fab"><i class="fas fa-arrow-alt-circle-left" ></i></div></a>
			</div>
			<!-- End Tags -->

		</div>
		<!-- End Post -->

	</div>
</div>

<!-- <div class="relative" style="height: 36px;overflow: hidden;margin-top: -36px; z-index:2">
    <div class="w-full absolute bottom-0 start-0 end-0"
      style="transform: scale(2);transform-origin: top center;color: #6C55F9;">
      <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
  </div> -->
  <div class="custom-shape-divider-bottom-1667156080">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
        <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
        <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
        <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
</div>
<div class="custom-shape-divider-top-1667156508">
  <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
      <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
      <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
  </svg>
</div>
<!-- End Article
================================================== -->

