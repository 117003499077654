import { Component, Input, OnInit } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import { CanonicalService } from '../services/canonical.service';
import { AfterViewInit, ViewChild } from '@angular/core';

import { Observable, Subscription } from 'rxjs';
import AOS from "aos";
import {ContentfulTeamsService } from '../services/contentful-teams.service';



// import * as dataJSON from './carousel_images.json';

/**
 * @title Basic cards
 */

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [NgbCarouselConfig], // add NgbCarouselConfig to the component providers
})
export class HomeComponent implements OnInit {

  constructor(
    config: NgbCarouselConfig,
    private title: Title,
    private contentfulTeamsService: ContentfulTeamsService,
    private metaTagService: Meta,
    private canonicalService: CanonicalService,
  ) {
    // customize default values of carousels used by this component tree
    config.showNavigationArrows = true;
    config.showNavigationIndicators = true;
    config.interval = 8000; // images change in 8sec //
    config.wrap = true; // autometically redirect to first image //
    config.keyboard = true;
    config.pauseOnHover = true;
    
  }

  // Carousel
  showNavigationArrows = false;
  showNavigationIndicators = false;
  public images = [
    'https://res.cloudinary.com/dyzeuqi75/image/upload/v1655897697/pic4_hxprnw.png',
    'https://res.cloudinary.com/dyzeuqi75/image/upload/v1655898489/1655898411301_qtjnby.png',
    'https://res.cloudinary.com/dyzeuqi75/image/upload/v1655895763/pic3_ojl3ri.png',
    'https://res.cloudinary.com/dyzeuqi75/image/upload/v1655839240/3_cv9cc4.png',
  ];
  titles = ['iOS', 'Android', 'Web Apps', 'Cross-platform Apps'];
  discription = [
    'iOS mobile applications',
    'Android mobile applications',
    'Any type of website',
    'Same app working on iOS, Android and Web ',
  ];

  teamPosts$ : Observable<any> | undefined;

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.title.setTitle('Sputnik Tech');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Welcome to Sputnik Tech, learn more about us and what we offer.',
    });
    this.teamPosts$ = this.contentfulTeamsService.getAllEntries();
    AOS.init();  
  } 
}




