import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';
import { ContactService } from '../services/contact.service';
import AOS from 'aos';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent implements OnInit {
  FormData: UntypedFormGroup;
  constructor(
    private builder: UntypedFormBuilder,
    private contact: ContactService,
    private title: Title,
    private metaTagService: Meta,
    private canonicalService: CanonicalService
  ) {}

  ngOnInit(): void {
    AOS.init();
    this.canonicalService.setCanonicalURL();
    this.FormData = this.builder.group({
      Fullname: new UntypedFormControl('', [Validators.required]),
      Email: new UntypedFormControl('', [
        Validators.compose([Validators.required, Validators.email]),
      ]),
      Comment: new UntypedFormControl('', [Validators.required]),
    });
    this.title.setTitle('Contact Us');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Contact Sputnik Tech',
    });
  }

  onSubmit(FormData) {
    console.log(FormData);
    this.contact.PostMessage(FormData).subscribe(
      (response) => {
        location.href = 'https://mailthis.to/confirm';
        console.log(response);
      },
      (error) => {
        console.warn(error.responseText);
        console.log({ error });
      }
    );
  }
}
