import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { CanonicalService } from '../services/canonical.service';
import { ContentfulService } from '../services/contentful.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {

  constructor(
    
    private route: ActivatedRoute,
    private title: Title,
    private canonicalService: CanonicalService,
    private metaTagService: Meta,
    private contentfulService: ContentfulService
  ) { }

  blogPost$: Observable<any> | undefined;

  ngOnInit(): void {
    this.canonicalService.setCanonicalURL();
    this.title.setTitle('Blog details page');
    this.metaTagService.updateTag({
      name: 'description',
      content: 'Sputnik Tech blog details page',
    });
    this.route.params.subscribe(
      params => {
        const id = params['id'];
        this.blogPost$ = this.contentfulService.getEntryById(id);
      }
    );
  }
}
